import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        [
          _c(
            VForm,
            {
              ref: "form",
              attrs: { "lazy-validation": _vm.lazy },
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.get_requests_by_page()
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                VBtn,
                {
                  staticStyle: { right: "65px" },
                  attrs: {
                    color: "primary",
                    rounded: "",
                    loading: _vm.reportDownloading,
                    absolute: "",
                    small: "",
                    fab: "",
                    top: "",
                    right: "",
                  },
                  on: { click: _vm.download_allocations_report },
                },
                [_c(VIcon, [_vm._v("mdi-download")])],
                1
              ),
              _c(
                VBtn,
                {
                  attrs: {
                    "data-test": "Vehicle:UserInterest:BtnANewRequest",
                    color: "primary",
                    rounded: "",
                    absolute: "",
                    small: "",
                    fab: "",
                    top: "",
                    right: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.open_edit_dialog(null)
                    },
                  },
                },
                [_c(VIcon, [_vm._v("mdi-plus")])],
                1
              ),
              _vm.filter
                ? _c(
                    VRow,
                    { staticClass: "mt-6", attrs: { justify: "center" } },
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12", sm: "12", md: "4" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              dense: "",
                              outlined: "",
                              label: _vm.$t("name") + "/" + _vm.$t("email"),
                            },
                            model: {
                              value: _vm.name_filter,
                              callback: function ($$v) {
                                _vm.name_filter = $$v
                              },
                              expression: "name_filter",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", sm: "12", md: "3", lg: "4" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              dense: "",
                              outlined: "",
                              label: _vm.$tc("company", 1),
                            },
                            model: {
                              value: _vm.company_filter,
                              callback: function ($$v) {
                                _vm.company_filter = $$v
                              },
                              expression: "company_filter",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", sm: "12", md: "3" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.StatusFilterOptions,
                              "item-text": "text",
                              "item-value": "index",
                              label: _vm.$t("status"),
                            },
                            model: {
                              value: _vm.status_filter,
                              callback: function ($$v) {
                                _vm.status_filter = $$v
                              },
                              expression: "status_filter",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", sm: "12", md: "2", lg: "1" },
                        },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "secondary",
                              attrs: { type: "submit" },
                            },
                            [_vm._v(_vm._s(_vm.$t("apply")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(VSimpleTable, [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "table-head", attrs: { scope: "col" } },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("name"))),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color:
                            _vm.orderBy == _vm.orderFilterEnum.NAME
                              ? "primary"
                              : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(_vm.orderFilterEnum.NAME)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == _vm.orderByModeEnum.ASC
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "table-head", attrs: { scope: "col" } },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("email"))),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color:
                            _vm.orderBy == _vm.orderFilterEnum.EMAIL
                              ? "primary"
                              : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(_vm.orderFilterEnum.EMAIL)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == _vm.orderByModeEnum.ASC
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "table-head", attrs: { scope: "col" } },
                  [
                    _vm._v(" " + _vm._s(_vm.$tc("background_check", 1)) + " "),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color:
                            _vm.orderBy == _vm.orderFilterEnum.BACKGROUND_CHECK
                              ? "primary"
                              : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(
                              _vm.orderFilterEnum.BACKGROUND_CHECK
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == _vm.orderByModeEnum.ASC
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "table-head", attrs: { scope: "col" } },
                  [
                    _vm._v(" " + _vm._s(_vm.$tc("partner", 1))),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color:
                            _vm.orderBy == _vm.orderFilterEnum.PARTNER
                              ? "primary"
                              : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(_vm.orderFilterEnum.PARTNER)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == _vm.orderByModeEnum.ASC
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "table-head", attrs: { scope: "col" } },
                  [
                    _vm._v(" " + _vm._s(_vm.$tc("company", 1))),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color:
                            _vm.orderBy == _vm.orderFilterEnum.COMPANY
                              ? "primary"
                              : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(_vm.orderFilterEnum.COMPANY)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == _vm.orderByModeEnum.ASC
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "table-head", attrs: { scope: "col" } },
                  [
                    _vm._v(" " + _vm._s(_vm.$tc("vehicle_title_single")) + " "),
                    _c(
                      VIcon,
                      { staticClass: "ml-2", attrs: { small: "" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == _vm.orderByModeEnum.ASC
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "table-head", attrs: { scope: "col" } },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("date"))),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color:
                            _vm.orderBy == _vm.orderFilterEnum.DATE
                              ? "primary"
                              : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(_vm.orderFilterEnum.DATE)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == _vm.orderByModeEnum.ASC
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "table-head", attrs: { scope: "col" } },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("value"))),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color:
                            _vm.orderBy == _vm.orderFilterEnum.VALUE
                              ? "primary"
                              : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(_vm.orderFilterEnum.VALUE)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == _vm.orderByModeEnum.ASC
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "th",
                  { staticClass: "table-head", attrs: { scope: "col" } },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("status"))),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color:
                            _vm.orderBy == _vm.orderFilterEnum.STATUS
                              ? "primary"
                              : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(_vm.orderFilterEnum.STATUS)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == _vm.orderByModeEnum.ASC
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-center table-head",
                    attrs: { scope: "col" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("commitment_term")) + " ")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "text-center table-head",
                    attrs: { scope: "col" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("options")) + " ")]
                ),
                _c(
                  "th",
                  { staticClass: "table-head", attrs: { scope: "col" } },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("status_description"))),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color:
                            _vm.orderBy ==
                            _vm.orderFilterEnum.STATUS_DESCRIPTION
                              ? "primary"
                              : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(
                              _vm.orderFilterEnum.STATUS_DESCRIPTION
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == _vm.orderByModeEnum.ASC
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.requests, function (req, index) {
                return _c("tr", { key: index }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.redirect_user(req.User)
                        },
                      },
                    },
                    [
                      _c(
                        "td",
                        [
                          _vm._v(" " + _vm._s(_vm.show_name(req.User)) + " "),
                          req.User.IsAlpha
                            ? _c(
                                VTooltip,
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              VIcon,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color: "secondary",
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("mdi-alpha")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("alpha_user"))),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("td", [_vm._v(_vm._s(req.User.Email))]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          req.User.BackgroundChecks
                            ? _vm.get_bc_result(req.User.BackgroundChecks[0])
                            : "-"
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          req.User.PartnerUser
                            ? _vm.show_name(req.User.PartnerUser.Partner)
                            : "-"
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", [_vm._v(" " + _vm._s(req.Company.Name) + " ")]),
                  req.InvestmentVehicle
                    ? _c("td", [
                        _vm._v(" " + _vm._s(req.InvestmentVehicle.Name) + " "),
                      ])
                    : _c("td", [_vm._v("-")]),
                  _c("td", [_vm._v(_vm._s(_vm.convert_date(req.Timestamp)))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.formatToCurrency(req.Currency, req.Value))
                    ),
                  ]),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.$t("" + _vm.parseRequestStatus(req.Status)))
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _vm.getCommitmentTerm(req.Files)
                        ? _c(
                            VBtn,
                            {
                              staticClass: "mx-1 secondary",
                              attrs: {
                                small: "",
                                "min-width": "0",
                                loading:
                                  _vm.fileDownloading ==
                                  _vm.getCommitmentTerm(req.Files).Path,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.get_doc_path(
                                    _vm.getCommitmentTerm(req.Files)
                                  )
                                },
                              },
                            },
                            [_c(VIcon, [_vm._v("mdi-file-document")])],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "data-test":
                              "Vehicle:UserInterest:BtnEdit:" + req.Value,
                            color: "secondary",
                            "min-width": "0",
                            small: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.open_edit_dialog(req)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-pencil"),
                          ]),
                        ],
                        1
                      ),
                      _vm.showApproveButton(req.Status)
                        ? _c(
                            VBtn,
                            {
                              staticClass: "mx-1",
                              attrs: {
                                "min-width": "0",
                                color: "primary",
                                small: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.open_delete_dialog(req, 0)
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-check-bold"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      req.Status != 2
                        ? _c(
                            VBtn,
                            {
                              staticClass: "mx-1",
                              attrs: {
                                "data-test":
                                  "Vehicle:UserInterest:BtnDelete:" + req.Value,
                                "min-width": "0",
                                color: "red",
                                small: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.open_delete_dialog(req, 1)
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-close-thick"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("td", [
                    req.StatusDescription
                      ? _c("div", [
                          _c(
                            "p",
                            {
                              class:
                                "status-description " +
                                (req.StatusDescription.length > 120 &&
                                  "status-description-max-size"),
                            },
                            [_vm._v(" " + _vm._s(req.StatusDescription) + " ")]
                          ),
                          req.StatusDescription.length > 120
                            ? _c(
                                "span",
                                {
                                  staticClass: "read-more-status-description",
                                  on: {
                                    click: function () {
                                      _vm.dialogFullStatusDescription = {
                                        show: true,
                                        content: req.StatusDescription,
                                      }
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("read_more")))]
                              )
                            : _vm._e(),
                        ])
                      : _c("span", [_vm._v("-")]),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _c(VPagination, {
            attrs: { color: "secondary", length: _vm.total_pages },
            on: { input: _vm.changePage },
            model: {
              value: _vm.page,
              callback: function ($$v) {
                _vm.page = $$v
              },
              expression: "page",
            },
          }),
          _vm.dialog
            ? _c(
                VDialog,
                {
                  attrs: { scrollable: "" },
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c("AddUserInterestModal", {
                    attrs: { Interest: _vm.request },
                    on: {
                      close: function ($event) {
                        _vm.dialog = false
                      },
                      update: _vm.requests_updated,
                      reload: _vm.updatePage,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.delete_dialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.delete_dialog,
                    callback: function ($$v) {
                      _vm.delete_dialog = $$v
                    },
                    expression: "delete_dialog",
                  },
                },
                [
                  _c("DeleteConfirmationModal", {
                    attrs: {
                      localLoading: _vm.loadingRequestModal,
                      useLocalLoading: true,
                      obj: _vm.request,
                      CustomMessage: _vm.customMessage,
                    },
                    on: {
                      close: function ($event) {
                        _vm.delete_dialog = false
                      },
                      delete: _vm.change_status,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            VDialog,
            {
              attrs: { "max-width": "564" },
              model: {
                value: _vm.alertRequest,
                callback: function ($$v) {
                  _vm.alertRequest = $$v
                },
                expression: "alertRequest",
              },
            },
            [
              _c("AlertApproveRequest", {
                on: {
                  closeDialog: function ($event) {
                    _vm.alertRequest = false
                  },
                },
              }),
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: { "max-width": "564" },
              model: {
                value: _vm.dialogFullStatusDescription.show,
                callback: function ($$v) {
                  _vm.$set(_vm.dialogFullStatusDescription, "show", $$v)
                },
                expression: "dialogFullStatusDescription.show",
              },
            },
            [
              _c(
                VCard,
                { class: _vm.gs.isMobile() ? "" : "dxa_modal" },
                [
                  _c(VCardTitle, { staticClass: "mb-2" }, [
                    _c("h4", { staticClass: "dxa_modal_title h4" }, [
                      _vm._v(_vm._s(_vm.$t("status_description"))),
                    ]),
                  ]),
                  _c(
                    VCardText,
                    [
                      _c("p", { staticClass: "status-description-full-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.dialogFullStatusDescription.content) +
                            " "
                        ),
                      ]),
                      _c(
                        VCardActions,
                        { staticClass: "pl-0 dxa_modal_actions" },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "dxa_modal_btnError",
                              attrs: { color: "secondary", "min-width": "100" },
                              on: {
                                click: function () {
                                  _vm.dialogFullStatusDescription = {
                                    show: false,
                                    content: null,
                                  }
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }