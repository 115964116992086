import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VForm,
    {
      ref: "form",
      staticClass: "mt-5",
      on: {
        submit: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.checkForm()
        },
      },
    },
    [
      _c(VRow, { staticClass: "ml-0 pb-5", attrs: { align: "center" } }, [
        _c("div", [
          _c("span", { staticClass: "register-links-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("alpha_register")) + " "),
          ]),
          _c("ul", { staticClass: "register-links-list" }, [
            _vm.alphaRegisterUrlPt
              ? _c(
                  "li",
                  { staticClass: "flex-center" },
                  [
                    _c("div", { staticClass: "flex-center" }, [
                      _c("span", { staticClass: "block mr-2 link-label" }, [
                        _vm._v(_vm._s(_vm.$t("br_investor")) + ":"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.alphaRegisterUrlPt))]),
                    ]),
                    _c(
                      VBtn,
                      {
                        staticClass: "ml-3",
                        attrs: { "x-small": "", icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.gs.copy_url_to_clipboard(
                              _vm.alphaRegisterUrlPt
                            )
                          },
                        },
                      },
                      [
                        _c(VIcon, { attrs: { color: "primary" } }, [
                          _vm._v("mdi-content-copy"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.alphaRegisterUrlEn
              ? _c(
                  "li",
                  { staticClass: "flex-center" },
                  [
                    _c("div", { staticClass: "flex-center" }, [
                      _c("span", { staticClass: "block mr-2 link-label" }, [
                        _vm._v(_vm._s(_vm.$t("us_investor")) + ":"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.alphaRegisterUrlEn))]),
                    ]),
                    _c(
                      VBtn,
                      {
                        staticClass: "ml-3",
                        attrs: { "x-small": "", icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.gs.copy_url_to_clipboard(
                              _vm.alphaRegisterUrlEn
                            )
                          },
                        },
                      },
                      [
                        _c(VIcon, { attrs: { color: "primary" } }, [
                          _vm._v("mdi-content-copy"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }